var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "gala-world-view px-0 grey lighten-1",
      attrs: { fluid: "" }
    },
    [
      _c(
        "div",
        { staticClass: "mt-6" },
        _vm._l(_vm.menuSections, function(section, index) {
          return _c(
            "v-list",
            {
              key: "section-" + index,
              staticClass: "custom-list",
              attrs: { dense: "", flat: "" }
            },
            [
              _c("v-subheader", [_vm._v(_vm._s(_vm.$t(section.title)))]),
              _c(
                "v-list-item-group",
                { attrs: { color: "primary" } },
                _vm._l(section.items, function(item, i) {
                  return _c(
                    "v-list-item",
                    {
                      key: "item-" + i,
                      on: {
                        click: function($event) {
                          return _vm.handleLogin(
                            item.routePath,
                            item.auth,
                            item.external
                          )
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          item.icon != ""
                            ? _c("v-icon", {
                                attrs: { color: "primary" },
                                domProps: {
                                  textContent: _vm._s("$" + item.icon)
                                }
                              })
                            : _c("img", {
                                staticClass: "small-icon",
                                attrs: { src: item.img }
                              })
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.$t(item.title)))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        }),
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }